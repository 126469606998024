import React from "react";




const ProductAttributes=() =>{
   
    return (
      <>
        <h1>
          Product details
        </h1>

    </>
    )
  }
  export default ProductAttributes;